<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <c-budget-forms-data-transfer
                    v-if="!isGkkpMode && !(progress < 100) && variantAttribute"
                    :header="header"
                    :lng="lng"
                    :getDataTransferText="getDataTransferText"
                    :getErrText="getErrText"
                    :makeToast="makeToast"
                    @reloadTable="loadDatas"
                />
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button :style="{ 'minWidth': '110px' }" variant="primary" @click="addItem" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</b-button>
                    <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">{{ getCommonText('save') }}</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering table-primary">8</td>
                    <td class="td-numbering table-info">9</td>
                    <td class="td-numbering table-warning">10</td>
                    <td class="td-numbering table-primary">11</td>
                    <td class="td-numbering table-success">12</td>
                    <td class="td-numbering table-danger">13</td>
                    <td class="td-numbering table-info">14</td>
                    <td class="td-numbering table-primary">15</td>
                    <td class="td-numbering table-warning">16</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(select)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItemWithAttachedFiles(`${selectAll ? getDecText('del_all_recs') : getDecText('del_selected_recs')}`)"/>
                    </div>
                </template>

                <template #cell(select)="data">
                    <b-form-checkbox
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(model)="data">
                    <div class="error">
                        <template v-if="variantAttribute">
                            <multiselect v-if="data.item.dict"
                                        style="width: 300px"
                                        v-model="data.item.modelName"
                                        track-by="text"
                                        label="text"
                                        :placeholder="getFormText('secect_model')"
                                        :options="dict"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="(dictItem) => {changeModel(data.item, dictItem.value); doublesValidation();}"
                            >
                            </multiselect>
                            <b-form-input v-if="!data.item.dict"
                                        style="min-width: 250px;"
                                        :title="data.item.model"
                                        :value="data.item.model"
                                        @change="v => {data.item.model = v; doublesValidation();}"
                                        @keyup.enter.exact="keyup13">
                            </b-form-input>
                        </template>
                        <div v-else>{{ data.value }}</div>
                        <template v-if="(data.item.isHasDouble)">
                            <i class="icon icon-danger decodes-error-icon"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                <p>{{ getCommonText("exist") }}</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #cell(winter)="data">
                    <b-form-select v-if="variantAttribute"
                                   size="sm"
                                   style="width: 70px"
                                   v-model="data.item.winter"
                                   :options="win_sum"
                                   @input="doublesValidation"
                    >
                    </b-form-select>
                    <div v-else>{{ win_sum.find(el => el.value === data.value).text }}</div>
                </template>
                <template #cell(months)="data">
                    <b-form-input v-if="variantAttribute" 
                                class="text-right" 
                                v-model="data.item.months"
                                :disabled="!data.item.model || !data.item.kind_fuel"
                                maxlength = "4"
                                @keyup.enter.exact="keyup13"
                                @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                @blur="inputFixed(data.item, 'months', data.item.months, 1, 1)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.months === null || data.item.months === '' || data.item.months <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(kind_fuel)="data">
                    <multiselect v-if="variantAttribute"
                                  :title="data.item.kind_fuel ? data.item.kind_fuel.text : null"
                                   size="sm"
                                   style="width: 145px"
                                   v-model="data.item.kind_fuel"
                                   label="text"
                                   :internal-search="false"
                                   :searchable="false"
                                   :options="dictGsmFiltered"
                                   @open="disableUnfilteredGsm"
                                   @input="doublesValidation"
                    >
                    </multiselect>
                    <div v-else>{{ data.value.text }}</div>
                </template>
                <template #cell(number_cars)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.number_cars"
                                  @change="v => data.item.number_cars = v"
                                  :disabled="!data.item.model || !data.item.kind_fuel"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixedVldtn(data.item, 'number_cars', data.item.number_cars, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.number_cars === null || data.item.number_cars === '' || data.item.number_cars <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(engine)="data">
                    <template v-if="variantAttribute">
                        <template v-if="data.item.dict">
                            <template v-if="(data.item.model !== null) && (data.item.model.engine == null)">
                                <b-form-input class="text-right"
                                              :value="data.item.engine"
                                              @change="v => data.item.engine = v"
                                              :disabled="!data.item.model || !data.item.kind_fuel"
                                              @keyup.enter.exact="keyup13"
                                              @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                              @blur="{inputFixedVldtn(data.item, 'engine', data.item.engine, 2); doublesValidation()}">
                                </b-form-input>
                            </template>
                            <template v-else>
                                <div class="text-right">{{ $n(data.value) }}</div>
                            </template>
                        </template>
                        <b-form-input v-if="!data.item.dict"
                                      class="text-right"
                                      :value="data.item.engine"
                                      @change="v => data.item.engine = v"
                                      :disabled="!data.item.model || !data.item.kind_fuel"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="{inputFixedVldtn(data.item, 'engine', data.item.engine, 2); doublesValidation()}">
                        </b-form-input>
                    </template>
                    <div v-else>{{ data.value }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.engine === null || data.item.engine === '' || data.item.engine <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(base_rate)="data">
                    <b-form-input v-if="variantAttribute"
                        class="text-right"
                        style="width: 70px"
                        :value="data.item.base_rate"
                        @change="v => data.item.base_rate = v"
                        :disabled="!data.item.model || !data.item.kind_fuel"
                        @keyup.enter.exact="keyup13"
                        @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                        @blur="inputFixedVldtn(data.item, 'base_rate', data.item.base_rate, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.base_rate === null || data.item.base_rate === '' || data.item.base_rate <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(limit_mile)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  style="width: 70px"
                                  :value="data.item.limit_mile"
                                  @change="v => data.item.limit_mile = v"
                                  :disabled="!data.item.model || !data.item.kind_fuel"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixedVldtn(data.item, 'limit_mile', data.item.limit_mile, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.limit_mile === null || data.item.limit_mile === '' || data.item.limit_mile <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(cost_fuel)="data">
                    <b-form-input v-if="variantAttribute"
                                  style="min-width: 55px;"
                                  class="text-right"
                                  :value="data.item.cost_fuel"
                                  @change="v => data.item.cost_fuel = v"
                                  :disabled="!data.item.model || !data.item.kind_fuel"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'cost_fuel', data.item.cost_fuel, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.cost_fuel === null || data.item.cost_fuel === '' || data.item.cost_fuel <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(cost_coeff)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.cost_coeff"
                                  @change="v => data.item.cost_coeff = v"
                                  :disabled="!data.item.model || !data.item.kind_fuel"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\-?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'cost_coeff', data.item.cost_coeff, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ isNaN(data.value) ? 0 : $n(data.value) }}</div>
                </template>
                <template #cell(note)="data">
                    <div :class="{ 'error': ((data.item.note && data.item.note.length > 255)
                    || (data.item.cost_coeff !== 0 && data.item.note.trim().length === 0)) }">
                        <div class="note-box">
                            <b-form-input
                                class="text-right"
                                style="min-width: 90%"
                                :value="data.item.note"
                                @change="v => data.item.note = v"
                                @keyup.enter.exact="keyup13">
                            </b-form-input>
                            <i v-if="variantAttribute"
                                class="icon icon-more thin-blue pointer icon-18 icon-rotate-90" 
                                @click="onEditNote(data.item)">
                            </i>
                        </div>
                        <template v-if="(data.item.note && data.item.note.length > 255)">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getCommonText('not_valid') }} </p>
                                <p>{{ getCommonText('len_limit_255') }}</p>
                            </div>
                        </template>
                        <template v-else-if="(data.item.cost_coeff !== 0 && data.item.note.trim().length === 0)">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getCommonText('not_valid') }} </p>
                                <p>{{ getCommonText('required') }}</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onFilesClick(data.item)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_rec')" 
                            class="icon icon-clear table-remove" 
                            v-if="variantAttribute" 
                            @click="deleteItemWithAttachedFiles(`${getCommonText('del_rec')}?`, data.item, data.index)"
                        ></i>
                    </div>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="12">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ isNaN(cost_allcar) ? 0 : $n(cost_allcar) }}</td>
                    <td class="text-right">{{ isNaN(cost_coeff) ? 0 : $n(cost_coeff) }}</td>
                    <td class="text-right">{{ isNaN(total) ? 0 : $n(total) }}</td>
                    <td colspan="3"></td>
                </template>
            </b-table>
        </div>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="addItem"><i class="icon icon-plus-circle"></i>{{ getCommonText('add') }}</span>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      @onFilePreview="onFilePreview"
                      ref="fileUpdown"
        ></files-updown>
        <modal-files-management-nodecode
            ref="modalFilesManagement"
            :variant-attribute="variantAttribute"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            @fileUpload="fileUpload"
            @toggleIsAdd="toggleIsAdd($event)"
        />
        <modal-note
            ref="setNoteModal"
            :noteFieldName="noteFieldName"
            :getCommonText="getCommonText"
            :onEditNote="onEditNote"
        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import Multiselect from 'vue-multiselect';
import ModalFilesManagementNodecode from './components/modal-files-management-nodecode.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import ModalNote from './components/modal-note.vue';
import CBudgetFormsDataTransfer from './components/budget-forms-data-transfer.vue';

export default {
    name: 'Form01_144',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        FormsDownloadReprt, 
        'multiselect': Multiselect, 
        BreadcrumbsFilter, 
        ModalFilesManagementNodecode, 
        CBudgetFormsCopyData,
        ModalNote,
        CBudgetFormsDataTransfer
    },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '01-144',
                name_kk: 'Мемлекеттiк органның жанар-жағармай материалдарына арналған шығыстарды есептеу',
                name_ru: 'Расчет расходов государственных органов на горюче-смазочные материалы'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dict: [],
            dictClimatConds: [],
            files: null,
            load: false,
            openDisabled: false,
            dictGsm: [],
            dictGsmFiltered: [],
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
            noteFieldName: 'note'
        };
    },
    async mounted() {
        await this.loadClimats();
        await this.loadGsm();
        await this.loadDict();
    },
    watch: {
        lang: {
            handler() {
                this.setDictGsmLocName();
            }
        },
        budgetForm: function() {
             this.disableUnfilteredGsm();
        },
    },
    methods: {
        addItem() {
            if (this.isDoubleDecodeExist()) return;
            const that = this;
            const item = {
                id: this.newRowStartId,
            };
            this.newRowStartId--;
            that.itemUpdate(item);

            Object.defineProperty(item, 'cost_rate', {
                get: function () {
                    const costRate = item.limit_mile / 100 * item.base_rate;
                    return parseFloat((Math.round(costRate * 100) / 100).toFixed(2));
                }
            });
            Object.defineProperty(item, 'cost_onecar', {
                get: function () {
                    const costOnecar = item.cost_rate * item.cost_fuel / 1000;
                    return parseFloat((Math.round(costOnecar * 100) / 100).toFixed(2));
                }
            });
            Object.defineProperty(item, 'cost_allcar', {
                get: function () {
                    const costAllcar = item.cost_onecar * item.number_cars * item.months;
                    return parseFloat((Math.round(costAllcar * 100) / 100).toFixed(2));
                }
            });
            Object.defineProperty(item, 'cost_total', {
                get: function () {
                    const total = parseFloat(item.cost_allcar) + parseFloat(item.cost_coeff);
                    return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                }
            });
            that.budgetForm.push(item);
        },

        async itemUpdate(item){
            this.$set(item, 'dict', true);
            this.$set(item, 'code', null);
            this.$set(item, 'model', null);
            this.$set(item, 'engine', 0);
            this.$set(item, 'base_rate', 0);
            this.$set(item, 'winter', 0);
            this.$set(item, 'months', 1);
            this.$set(item, 'kind_fuel', null);
            this.$set(item, 'number_cars', 0);
            this.$set(item, 'limit_mile', 0);
            this.$set(item, 'cost_fuel', 0);
            this.$set(item, 'cost_coeff', 0);
            this.$set(item, 'modelName', null);
            this.$set(item, 'itemToDelete', false);
            this.$set(item, 'note', '');
            this.$set(item, 'files', 0);
            this.$set(item, 'isHasDouble', false);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} itemUpdate`, error.toString());
            }
            this.load = false;
        },

        changeModel(item, model) {
            this.$set(item, 'code', model);
            this.$set(item, 'model', model.model);
            this.$set(item, 'engine', (model.engine === null ? null : model.engine / 1000));
        },

        calcTotal(ids) {
            this.$set(this.header, 'value', this.calcTotalForDelete(this.budgetForm, 'cost_total', ids));
        },

        getFiles(data) {
            this.files = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код


        async loadDatas() {
            this.load = true;
            await this.budgetForm.splice(0);
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                values = await response.json();
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas()`, error.toString());
            }

            await values.forEach(val => {
                const item = {
                    id: val.id,
                    dict: false,
                    code: null,
                    model: val.model,
                    engine: val.engine,
                    base_rate: val.base_rate,
                    winter: val.winter,
                    months: val.months,
                    kind_fuel: this.getGsm(val.kind_fuel, this.dictGsm),
                    number_cars: val.number_cars,
                    limit_mile: val.limit_mile,
                    cost_fuel: val.cost_fuel,
                    files: val.files,
                    row_files: val.row_files,
                    itemToDelete: false,
                    cost_coeff: val.cost_coeff,
                    isHasDouble: false,
                };

                val.note ? this.$set(item, 'note', val.note)
                    : this.$set(item, 'note', '');

                Object.defineProperty(item, 'cost_rate', {
                    get: function () {
                        const costRate = item.limit_mile / 100 * item.base_rate;
                        return parseFloat((Math.round(costRate * 100) / 100).toFixed(2));
                    }
                });
                Object.defineProperty(item, 'cost_onecar', {
                    get: function () {
                        const costOnecar = item.cost_rate * item.cost_fuel / 1000;
                        return parseFloat((Math.round(costOnecar * 100) / 100).toFixed(2));
                    }
                });
                Object.defineProperty(item, 'cost_allcar', {
                    get: function () {
                        const costAllcar = item.cost_onecar * item.number_cars * item.months;
                        return parseFloat((Math.round(costAllcar * 100) / 100).toFixed(2));
                    }
                });
                Object.defineProperty(item, 'cost_total', {
                    get: function () {
                        const total = parseFloat(item.cost_allcar) + parseFloat(item.cost_coeff);
                        return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                    }
                });
                this.budgetForm.push(item);
            });
            this.sortBudgetForm();
            this.doublesValidation();
            this.load = false;
        },

        getGsm(code, list) {
            for (const item of list) {
                if (item.value === code) {
                    return item;
                }
            }
            return null;
        }, 

        async loadClimats() {
            try {
                const response = await fetch('/api-py/dictionary/climat_conds/');
                this.dictClimatConds = await response.json();
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadClimats()`, error.toString());
            }
        },

        async loadGsm() {
            try {
                this.dictGsm = [];
                const response = await fetch('/api-py/dict_gsm/');
                const gsmList = await response.json();
                gsmList.forEach(gsm => {
                    this.dictGsm.push({
                        value: gsm.code,
                        text: this.getDictGsmLocName(gsm),
                        name_ru: gsm.name_ru,
                        name_kk: gsm.name_kk,
                        category: gsm.category,
                        $isDisabled: false,
                        sm: gsm.sm,
                    });
                });
                this.dictGsmFiltered = this.dictGsm;
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadGsm()`, error.toString());
            }
        },

        setDictGsmLocName() {
            this.dictGsm.forEach(gsm => this.$set(gsm, 'text', this.getDictGsmLocName(gsm)));
        },

        getDictGsmLocName(gsm) {
            let lng = 'ru';
            if (this.$i18n.locale === 'kk') {
                lng = 'kk';
            }
            return gsm[`name_${lng}`]
        },

        disableUnfilteredGsm() {
            if (!this.budgetForm.length) return;
            this.dictGsmFiltered = this.dictGsm.filter(itm => (!itm.sm));
        },

        async loadDict() {
            try {
                this.dict = [];
                const response = await fetch('/api-py/dictionary/transport_models/');
                const items = await response.json();

                for (const row of items) {
                    const el = {};
                    this.$set(el, 'value', row);
                    this.$set(el, 'text', row.model + ' / ' + (row.engine / 1000).toFixed(1).toString() + ' / ' + row.gearbox + ' / ' + row.base_rate);
                    this.dict.push(el);
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadTModels()`, error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        prepareForSave() {
            if (this.isDoubleDecodeExist()) {
                this.makeToast('warning', this.getErrText('not_saved'), this.getErrText('not_valid'));
                return;
            }
            const values = [];
            let error = false;
            for (const row of this.budgetForm) {
                if (this.optionNotSelected(row, this.tableFields, ['model', 'kind_fuel'])) return;
                else {
                    if (this.isSavingForbidden(row, this.tableFields, ['months', 'number_cars', 'engine', 'base_rate', 'limit_mile', 'cost_fuel'], [])) return;
                }
                if ((row.cost_coeff === 0 && (row.note === null || row.note.length < 256)) || (row.cost_coeff !== 0 && row.note.trim().length !== 0 && row.note.length < 256)) {
                    const item = Object.assign({}, this.header);
                    this.$set(item, 'id', row.id);
                    this.$set(item, 'model', row.model);
                    this.$set(item, 'engine', parseFloat(row.engine));
                    this.$set(item, 'base_rate', parseFloat(row.base_rate));
                    this.$set(item, 'winter', row.winter);
                    this.$set(item, 'number_cars', parseInt(row.number_cars));
                    this.$set(item, 'months', parseInt(row.months));
                    if (row.kind_fuel) {
                        this.$set(item, 'kind_fuel', row.kind_fuel.value);
                    }
                    this.$set(item, 'limit_mile', parseInt(row.limit_mile));
                    this.$set(item, 'cost_fuel', parseFloat(row.cost_fuel));
                    this.$set(item, 'cost_coeff', parseFloat(row.cost_coeff));
                    this.$set(item, 'note', row.note);
                    this.$set(item, 'row_files', row.row_files);
                    this.$set(item, 'cost_total', row.cost_total);
                    values.push(item);
                } else {
                    error = true;
                }
            }
            if (error) {
                this.onFieldsValidationFailed();
                return;
            }
            if (values.length > 0 && this.variantAttribute) {
                this.save(values, error);
            } else {
                this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
            }
        },

        async save(values, error) {
            this.isLoad = true;
            const totalToSave = this.totalCalculation(values, 'cost_total')
            try {
                const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : '/api-py/save-budget-request-form01-144/'
                this.$set(this.header, 'value', totalToSave);
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        afterDeleted() {
             this.doublesValidation();
        },

        async doublesValidation() {
            const doublesMap = {};
            await this.$nextTick();
            this.budgetForm.forEach(item => {
                const model = item.model ? item.model.trim() : '';
                const winter = item.winter;
                const kindFuel = item.kind_fuel ? item.kind_fuel.value : '';
                const engine = item.engine ? item.engine : '';
                
                if (model && kindFuel) {
                    const mapKey = `${model}_${winter}_${kindFuel}_${engine}`;
                    if (doublesMap[mapKey]) {
                        doublesMap[mapKey].push(item.id);   
                    } else {
                        doublesMap[mapKey] = [item.id];
                    }
                }
            })
            const doubleIdsList = []
            for (const key in doublesMap) {
                const idsList = doublesMap[key];
                const isKeyHasDoubles = idsList.length > 1;
                if (isKeyHasDoubles) {
                    doubleIdsList.push(...idsList);
                }
            }

            if (doubleIdsList.length > 0) {
                this.budgetForm.forEach(item => {
                    if (doubleIdsList.includes(item.id)) {
                        this.$set(item, 'isHasDouble', true);
                    } else {
                        this.$set(item, 'isHasDouble', false);
                    }
                })
            } else (
                this.budgetForm.forEach(item => this.$set(item, 'isHasDouble', false))
            )
        },

        sortBudgetForm() {
            this.budgetForm.sort((a, b) => {
                if (a.model && b.model && a.model !== b.model) {
                    if (a.model > b.model) return 1;
                    return -1;
                }

                if (a.winter !== b.winter) {
                    if (a.winter > b.winter) return 1;
                    return -1;
                }

                if (a.kind_fuel && b.kind_fuel && a.kind_fuel.text !== b.kind_fuel.text) {
                    if (a.kind_fuel.text > b.kind_fuel.text) return 1;
                    return -1;
                }

                return 0; 
            })
        }
    },
    computed: {
        cost_allcar() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.cost_allcar);
            }
            return sum;
        },
        cost_coeff() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.cost_coeff);
            }
            return sum;
        },
        total() {
            return this.totalCalculation(this.budgetForm, 'cost_total')
        },
        regCoeff() {
            const reg = this.padLeadingZeros(this.header.id_region.toString(), 6).substring(0, 2);

            for (const item of this.dictClimatConds) {
                if (item.code === reg) {
                    return parseFloat(item.coefficient);
                }
            }
            return 0;
        },

        getFormText() {
            return this.setFormText('form_01_144.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'select',
                        label: ' '
                    },
                    {
                        key: 'model',
                        label: this.getFormText('car_model')
                    },
                    {
                        key: 'winter',
                        label: `${this.getFormText('winter')}/${this.getFormText('summer')}`
                    },
                    {
                        key: 'kind_fuel',
                        label: this.getFormText('kind_fuel')
                    },
                    {
                        key: 'months',
                        label: this.getFormText('months')
                    },
                    {
                        key: 'number_cars',
                        label: this.getFormText('service_transport_count')
                    },
                    {
                        key: 'engine',
                        label: this.getFormText('engine_volume')
                    },
                    {
                        key: 'base_rate',
                        label: this.getFormText('basic_rate')
                    },
                    {
                        key: 'limit_mile',
                        label: this.getFormText('mileage_limit')
                    },
                    {
                        key: 'cost_rate',
                        label: this.getFormText('expense_norm1')
                    },
                    {
                        key: 'cost_fuel',
                        label: this.getFormText('fuel_lub_cost')
                    },
                    {
                        key: 'cost_onecar',
                        label: this.getFormText('cost_onecar')
                    },
                    {
                        key: 'cost_allcar',
                        label: this.getFormText('cost_allcar')
                    },
                    {
                        key: 'cost_coeff',
                        label: this.getFormText('cost_coeff')
                    },
                    {
                        key: 'cost_total',
                        label: this.getFormText('cost_total')
                    },
                    {
                        key: 'note',
                        label: this.getCommonText('notes')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        },

        win_sum() {
            if (this.$i18n.locale) {
                return [
                    {
                        value: 0,
                        text: this.getFormText('summer')
                    },
                    {
                        value: 1,
                        text: this.getFormText('winter')
                    },
                    {
                        value: 3,
                        text: this.getFormText('general')
                    }
                ];
            }
            return [];
        },    
    }
};
</script>
<style scoped>
    .goods-form-val-msg {
        font-size: 0.5rem;
        font-style: italic;
        color: red;
    }
    .filter-actions-flex {
        display: flex;
    }
    .note-box {
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
    }
    .icon-more {
        align-self: center;
        position: static;
    }
</style>